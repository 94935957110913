/* Home.css */

/* General Styles */
.home-container {
  min-height: 75vh;
}

/* Navbar Styles */
.navbar {
  background-color: #03b34c;
}

.navbar-brand {
  color: #0f0f0f;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-link {
  color: #0c0c0c;
}

.nav-link:hover {
  color: #f0f0f0;
  /* scroll-padding-left:12px; ;
  background-color: #f8f9f9c1;
  border-radius: 21px;
  padding-bottom: -8px; */
  
}

/* Hero Image Styles */
.hero-image {
  background-position: center;
  background-size: cover;
  background-color: rgb(28, 28, 28);
  height: 500px;
  width: 100%;
}

/* Section Styles */
.section {
  margin-top: 40px;
}

/* Content Container Styles */
.content-container {
  margin-top: 60px;
}

/* Image Container Styles */
.image-container {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

/* Text Center Styles */
.text-center {
  text-align: center;
}


.auto-typing {
  min-height: 4rem; /* Adjust based on the longest sentence */
  font-size: 1.5rem;
  color: green;
  text-align: center;
  font-weight: bold;
  margin-bottom: -65px; /* Space between text and buttons */
  margin-top: 22px;
}
/* Rounded Image Styles */
.rounded-img {
  width: 300px;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 5px solid rgb(161, 192, 167);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Team Members Styles */
.team-members {
  align-items: center;
  margin-top: 30px;
}

/* Team Member Card Styles */
.team-member-card {
  width: 18rem;
}

/* Responsive styles for small devices */
@media only screen and (max-width: 600px) {

  
  .navbar-collapse {
    background-image: url('../assets/Engineering\ team.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: auto;
    margin: 0 -12px -8px -12px;
    position: relative;
    overflow-x: hidden;
  }

  .auto-typing {
    min-height: 4rem; /* Adjust based on the longest sentence */
    font-size: 1rem;
    color: green;
    text-align: center;
    font-weight: bold;
    margin-bottom: -35px; /* Space between text and buttons */
  }
/* Dark overlay for text visibility */
.navbar-collapse::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5); /* 50% opacity black overlay */
  z-index: 1; /* Ensure the overlay appears above the background image */
}

/* Navbar content styling */
.navbar-collapse * {
  position: relative;
  z-index: 2; /* Ensure text and other elements appear above the overlay */
}
  
  .navbar-brand {
    font-size: 1.2rem;
  }

  .nav-link {
    font-size: 0.9rem;
    padding-left: 32px;
  }

  .nav-link:hover {
    color: #f0f0f0;
    scroll-padding-left:12px 12px ;
    background-color: #f8f9f9c1;
    border-radius: 21px;
  }

  .hero-image {
    height: 300px;
  }

  .rounded-img {
    width: 200px;
    height: 200px;
  }

  .team-member-card {
    width: 100%;
  }

  .home-container{
    min-height: '200vh';
  }
}

