/* Contact.css */

/* Styles for the Contact component */
.contact-container {
  min-height: 75vh;
  padding: 40px 0;
  background: linear-gradient(to bottom, #fbfbfb, white);
}

.contact-image {
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px; /* Futuristic feature: Add margin-bottom */
}

.contact-btn {
  display: inline-block;
  margin-top: 40px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #1ae253;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.contact-btn:hover {
  background-color: #b9c1c9;
  transform: scale(1.05); /* Scale up on hover */
}

.contact-heading {
  font-family: "Roboto", sans-serif; /* Choose a modern font */
  margin-top: 30px;
  text-align: center;
}

.contact-heading h1 {
  font-size: 3rem; /* Adjust the font size accordingly */
  font-weight: bold;
  color: #1ae253; /* Bright green color */
  text-transform: uppercase; /* Uppercase letters for a modern touch */
  letter-spacing: 2px; /* Add letter spacing for clarity */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle text shadow */
}

.contact-info {
  margin: 20px 0; /* Futuristic feature: Add separation margin */
}

.contact-info h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #20a046;
}

.contact-info h4 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 20px;
  color: #000;
}

.contact-info p {
  font-size: 1rem;
  margin: 10px 0;
  color: #000;
}

