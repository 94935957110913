/* App.css */

/* Global styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  

  /* Styling the active link in the Navbar */
  .nav-link.active {
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
  /* Navbar */
  .navbar {
    background-color: #040404;
    color: white;
  }
  
  .navbar-toggler {
    border-color: #17d861;
    border-width: 3px;
    border-style: solid;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 2em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  /* .navbar-brand img {
    margin-right: 10px;
  } */
  
/* Navbar brand styling */
.navbar-brand {
  display: flex;
  align-items: center;
  padding: 0;
  margin-right: 20px;
  text-decoration: none;
  transition: transform 0.3s ease;
  position: relative;
  z-index: 10;
}

.navbar-brand:hover {
  transform: scale(1.1); /* Slight scaling effect on hover */
  filter: drop-shadow(0px 4px 6px rgba(0, 255, 255, 0.7)); /* Glowing effect */
}

.navbar-brand img {
  margin-right: 10px;
  border-radius: 50%; /* Adds rounded edges for a futuristic look */
  border: 2px solid rgba(0, 255, 255, 0.3); /* Light neon border around the logo */
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.5); /* Neon glow effect around the logo */
  transition: box-shadow 0.3s ease, border 0.3s ease; /* Smooth transition on hover */
}

.navbar-brand:hover img {
  box-shadow: 0 0 25px rgba(0, 255, 255, 1); /* Stronger neon glow on hover */
  border: 2px solid rgba(0, 255, 255, 0.8); /* Enhanced border on hover */
}

/* Optional: Futuristic animation */
.navbar-brand img {
  animation: pulse-glow 2s infinite alternate;
}

/* Glow animation */
@keyframes pulse-glow {
  0% {
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 20px rgba(0, 255, 255, 1);
  }
}







  .nav-link {
    color: white;
    margin-right: 20px;
    height: 50px;
    margin-top: 10px; 
  }
  
  .nav-link:hover {
    color: #060606;
  }
  
  /* Main content */
  .App {
    margin-top: 0px;
  }
  
  /* Footer */
  footer {
    background-color: #1c1c1c;
    color: white;
    text-align: center;
    padding: 20px 0;
  }
  
  /* Social media icons in the footer */
  footer .social-icons a {
    color: white;
    margin: 0 5px;
    font-size: 24px;
  }
  
  /* Loading spinner */
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #00ff4c;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {

    .nav-link:hover {
      color: #0a9221;
    }

    .hmp-button{
      margin-top:12px;
        display: 'block';
        margin-left: 50px;
    }
  }
  


  