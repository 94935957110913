.privacy-policy {
  background: linear-gradient(135deg, #f2f3f4, #babcbe, #c1c5c7);
  color: #050505;
  padding: 2rem;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
}

.futuristic .title {
  color: #0f7a15;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.last-updated {
  text-align: center;
  font-style: italic;
}

.section {
  margin-bottom: 1.5rem;
}

.section-title {
  color: #0d823b;
  margin-top: 2rem;
}

.data-list, .usage-list, .rights-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.data-list li::before, .usage-list li::before, .rights-list li::before {
  content: "➤ ";
  color: #08561f;
}

.email-link {
  color: #109f33;
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}

.highlight {
  color: #0f8d32;
  font-weight: bold;
}
